
<template>
  <div>
    <div class="main">
      <div class="tips">
        <i class="el-icon-success"></i>
        <span>登记信息完成，已提交您的反馈。</span>
      </div>
      <template v-if="list.length > 0">
        <p>为您匹配了如下岗位</p>
        <ul class="job_li">
          <li v-for="item in list">
            <el-checkbox v-model="checkList" :label="item.id" value=""></el-checkbox>
            <div class="items">
              <div class="top">
                <a href="/job/jobDetail?id=1461239802406195200" class="line_clamp1" target="_blank" :title="item.jobName">
                  <div class="flex nameWrap">
                    <div class="name relative line_clamp1">
                      {{ item.jobName }}
                    </div>
                    <p class="label" v-if="item.isPressing == '1'">急聘</p>
                    <p class="money">{{ item.jobSalary }}</p>
                  </div>
                  <div class="address line_clamp1">
                    <span v-if="item.cityid">
                      {{ item.cityid
                      }}<span v-if="item.threeCityid">-{{ item.threeCityid }}
                      </span>
                    </span>
                    <span v-else> 城市不限 </span>
                    <el-divider direction="vertical"></el-divider>
                    <span v-if="item.edu">
                      {{ item.edu }}
                    </span>
                    <span v-else>学历不限</span>
                    <el-divider direction="vertical"></el-divider>
                    <span v-if="item.exp">
                      {{ item.exp }}
                    </span>
                    <span v-else>经验不限</span>
                    <el-divider direction="vertical"></el-divider>
                    <span v-if="item.distype.length > 2">
                      <span>多种残疾</span>
                    </span>
                    <span v-if="item.distype.length < 2 && item.distype.length != 0">
                      <span v-for="(n, i) in item.distype.slice(0, 2)" :key="i">
                        {{ n.disType }}
                        <em v-if="n.disTypeLevel">/</em>
                        {{ n.disTypeLevel }}
                      </span>
                    </span>
                  </div>
                  <div class="welfare line_clamp1">
                    <span v-if="
                        item.jobLabels == null || item.jobLabels.length <= 0
                      ">暂无福利标签</span>
                    <span v-for="(n, i) in item.jobLabels.slice(0, 3)" :key="i" v-else>{{ n.labelName }}</span>
                  </div>
                </a>
              </div>
              <div class="bottom flex cursorP" @click="
                  goRouter('/job/businessDetails', '', item.companyInfo.id)
                ">
                <img :src="item.companyInfo.logo || ''" :alt="item.companyInfo.companyName" :title="item.companyInfo.companyName" class="logo err_logo" style="overflow: hidden" />

                <div class="info">
                  <h3 class="line_clamp1 text_l">
                    {{ item.companyInfo.companyName }}
                  </h3>
                  <div class="type">
                    <span>
                      {{ item.companyInfo.pr || "暂无企业性质" }}
                    </span>
                    <el-divider direction="vertical" v-if="item.companyInfo.pr"></el-divider>
                    <span>
                      {{ item.companyInfo.hyTop }}
                    </span>
                    <el-divider direction="vertical" v-if="item.companyInfo.hyTop"></el-divider>
                    <span>
                      {{ item.companyInfo.mun }}
                      <i></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="btns">
          <el-button class="submit-btn" type="primary" @click="sendJob">一键投递</el-button>
          <a class="tomcdpee" href="https://lzmz.cdpee.org.cn/#/pages/index">
            <el-button class="submit-btn" type="primary" plain @click="sendJob">找工作学技能、快来看看吧</el-button>
          </a>
        </div>
      </template>

<!--      <p class="text">扫码绑定微信，获取更多福利。</p>-->
<!--      <img class="qrcode" src="~/static/gzh.png" alt="" />-->
      <div class="banner" v-for="banner in bannerList">
        <a :href="banner.picUrl">
          <img :src="banner.picSrc" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      checkList: [],
      bannerList: [],
    };
  },
  created() {
    this.getList();
    this.getBanner();
  },
  methods: {
    sendJob() {
      if (!this.checkList.length) {
        this.$message.error("请选择您要投递的简历");
        return false;
      }
      this.getMyDisAbledInfo();
    },
    submitJob() {
      this.$axios
        .get("/api/app-jycy-jobapply/disAbleAddJobApplys", {
          jobIds: this.checkList.toString(),
          fileResume: "0",
        })
        .then((res) => {
          res = res.data;
          if (res.success) {
            this.$message.error("投递成功" || "");
            this.getList();
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
    getMyDisAbledInfo() {
      this.$api
        .getMyDisAbledIfFull()
        .then((res) => {
          if (res.data.isComplete == 0) {
            this.$confirm("简历尚未完善，是否完善", "完善简历", {
              confirmButtonText: "去完善",
              type: "warning",
            })
              .then(() => {
                this.$router.push("/userLogin/userInfo");
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          } else {
            this.submitJob();
          }
        })
        .catch((error) => {
          console.log("错误了", error);
        });
    },
    getList() {
      this.$axios
        .get("/api/app-jycy-job/disableds/queryAboutHopeJob", {
          pageNum: 1,
          pageSize: 3,
        })
        .then((res) => {
          res = res.data;
          if (res.success) {
            let data = res.data;
            this.list = data.slice(0, 3);
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
    },
    getBanner() {
      this.$api
        .adApi({
          classId: "ff808081801b97d7018044f4bb0b0016",
        })
        .then((res) => {
          res = res.data;
          if (res.success) {
            this.bannerList = res.data || [];
            this.bannerList = this.bannerList.map((e) => ({
              ...e,
              picUrl: this.picUrl(e.picUrl),
            }));
          }
        });
    },
    picUrl(url) {
      if (!url) return null;
      return url.substr(0, 7).toLowerCase() == "http://"
        ? url
        : "http://" + url;
    },
  },
};
</script>
<style lang='less' scoped>
@import "~assets/css/public.less";
/deep/ .el-checkbox__label {
  display: none;
}
.main {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  .tomcdpee {
    display: none;
  }
  @media screen and (max-width: 980px) {
    width: 90%;
    .tomcdpee {
      display: block;
    }
  }
  .tips {
    text-align: center;
    margin: 20px 0 10px;
    i {
      color: #28a46d;
      font-size: 20px;
    }
    i,
    span {
      vertical-align: middle;
    }
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .job_li {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 980px) {
      width: 100%;
      flex-direction: column;
      overflow: hidden;
    }
    & > li {
      display: flex;
      align-items: center;
      @media screen and (max-width: 980px) {
        padding-left: 20px;
      }
      .el-checkbox {
        margin-right: 10px;
      }
    }
    .items {
      position: relative;
      margin-top: 24px;
      margin-right: 24px;
      flex-direction: column;
      //   width: 384px;
      flex: 1;
      height: 172px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid rgba(0, 18, 10, 0.03);
      cursor: pointer;
      transition: all 0.6s;
      box-sizing: border-box;
      @media screen and (max-width: 980px) {
        margin-right: 10px;
      }
      .top {
        margin: 0 18px;
        flex: 1;

        a {
          max-width: 386px;
          float: left;
          width: 100%;
        }

        .nameWrap {
          width: 100%;
          padding-top: 18px;
          //justify-content: space-between;
        }

        .name {
          font-size: 18px;
          max-width: 70%;
          font-weight: 600;
        }

        .label {
          width: 36px;
          height: 19px;
          background: #e60020;
          border-radius: 4px;
          transform: translateY(1px);
          margin-left: 3px;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          line-height: 19px;
        }

        .money {
          position: absolute;
          right: 20px;
          color: #ff5500;
          font-size: 16px;
          font-weight: bold;
        }

        .address {
          padding-top: 9px;
          height: 30px;

          span {
            position: relative;
            color: #666666;
          }

          .el-divider--vertical:last-child {
            display: none;
          }
        }

        .welfare {
          height: 45px;
          padding: 9px 0 11px 0;
          border-bottom: 1px solid rgba(0, 18, 10, 0.05);
          text-align: left;
          span {
            display: inline-block;
            font-size: 12px;
            padding: 0 8px;
            margin-right: 12px;
            line-height: 22px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 6px;
            color: #28a46d;
            background: #f9f9f9;
          }
        }
      }

      .bottom {
        height: 64px;
        margin-top: 11px;
        padding: 0 18px;

        .logo {
          width: 40px;
          height: 40px;
          min-width: 40px;
          min-height: 40px;
          margin-top: 4px;
          margin-right: 12px;
        }

        .info {
          margin-top: 4px;

          h3 {
            max-width: 280px;
            font-size: 14px;
            font-weight: 400;
          }

          h3:hover {
            color: #00924b;
          }

          .type {
            font-size: 12px;
            color: @gray;

            span {
              position: relative;
            }

            .el-divider--vertical:last-child {
              display: none;
            }
          }
        }
      }
    }
    .items:nth-of-type(3n) {
      margin-right: 0;
    }
  }
  .btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      .submit-btn {
        margin-bottom: 40px;
      }
    }
  }
  .submit-btn {
    min-width: 210px;
    margin-top: 20px;
    @media screen and (min-width: 980px) {
      margin-bottom: 40px;
    }
  }
  .text {
    color: #28a46d;
    font-size: 14px;
  }
  .qrcode {
    width: 150px;
    height: 150px;
    margin: 10px auto;
    background: #666666;
  }
  .banner {
    width: 100%;
    a {
      display: inline-block;
      width: 100%;
      height: 150px;
      background: #666666;
      margin-bottom: 15px;
      cursor: pointer;
      @media screen and (max-width: 980px) {
        height: 110px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
<style lang='less'>
@media screen and (max-width: 980px) {
  #app,
  .main {
    width: 100%;
    min-width: 100%;
  }
}
</style>